import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import { Divider } from 'CHAMELEON_REACT_HUB';
import List from 'components/LinkCard/List';
import ListItem from 'components/LinkCard/ListItem';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "last-components",
      "style": {
        "position": "relative"
      }
    }}>{`Last components`}</h2>
    <p>{`With these two last components, Tooltip and WideList, we'll be putting an end to
our first batch of components!`}</p>
    <List mdxType="List">
  <ListItem title="Tooltip" to="/components/tooltip/code" description="" mdxType="ListItem" />
  <ListItem title="WideList" to="/components/widelist/code" description="" mdxType="ListItem" />
    </List>
    <h2 {...{
      "id": "its-the-final-countdown",
      "style": {
        "position": "relative"
      }
    }}>{`It's the final countdown`}</h2>
    <p>{`Behind the scenes we're currently working hard on finalizing the last bits and
pieces for a major 1.0 release, as we're confident in the current ability to
build scalable and inherently multibranded products.`}</p>
    <p>{`With 37 components at both a designer's and a developer's fingertips, we feel
that we've reached a certain level of maturity that allows use to proudly
publish a first major version.`}</p>
    <p>{`So stay tuned for that first major milestone!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      